
import allSponsors from "../../../static/data/sponsors.json";
import SponsorCards from "./SponsorCards";

const sponsors = allSponsors.sponsors;
//const former_sponsors = allSponsors.former_sponsors;

export default function Sponsors() {
  return (
    <div id="sponsors">
      <h1>Partners</h1>
      <SponsorCards tier="Gold" sponsors={sponsors.gold} />

      
    </div>
  );
}
