export default function Schedule() {
  return (
    <div>
      <h1>Schedule</h1>
      <h6>
        Tuesdays from 6:00 pm to 8:00 pm in room 117 in the Main Building
      </h6>
      <div id="projects">
      </div>
    </div>
  );
}
