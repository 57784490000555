import { Container } from "@mui/system";
import peopleImage from "../../../static/images/people/people1.png";
import BlueOffsetImage from "../../assets/BlueOffsetImage";
import DiscordButton from "../../assets/DiscordButton";
import { Box } from "@mui/material";
import GroupmeButton from "../../assets/GroupmeButton";

export default function HowToJoin() {
  const containerStyle = {
    display: "flex",
    direction: "row",
    justifyContent: "center",
  };

  const descriptionContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const descriptionStyle = {
    textAlign: {
      xs: "center",
      sm: "center",
      md: "left",
      lg: "left",
    },
  };

  const imageContainerStyle = {
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
    flexGrow: 1,
    justifyContent: "center",
  };
  return (
    <div>
      <h1>How To Join</h1>
      <Container maxWidth="md">
        <div style={containerStyle}>
          <Container maxWidth="sm" sx={descriptionContainerStyle}>
            <Box sx={descriptionStyle}>
              <p>
                Sea Aggie Coding Club is a direct chapter of Aggie Coding Club in College
                Station and is open to all Texas A&M students regardless
                of coding experience or major at any point during the semester.
                Dues are <strong>$10</strong> and{" "}
                <strong>no application forms</strong>.
              </p>
              <p>
                Simply start attending our weekly meetings! Follow the link
                below to join our Discord to connect and be informed of our
                meeting times.
              </p>
              <div className="flex sm:flex-row flex-col gap-10 items-center">
              <DiscordButton />
              <GroupmeButton />
              </div>

            </Box>
          </Container>
          <Box sx={imageContainerStyle}>
            <BlueOffsetImage src={peopleImage} alt="people in ACC having fun" width={375} height={300}/>
          </Box>
        </div>
      </Container>
      <div id="schedule">
      </div>
    </div>
  );
}
