import OfficerCards from "./OfficerCards";

import allOfficers from "../../../static/data/officers.json";

//{/*import { useState } from "react";*/}
//{/*import { SquareButton } from "../../assets/SquareButton";*/}

const officers = allOfficers.officers;
//{/*const formerOfficers = allOfficers.former;*/}

export default function OfficerTeam() {
  // keeps track of button state to know whether to display officers or not
  //{/*const [displayFormerOfficers, setDisplayFormerOfficers] = useState(false);*/}

  //{/*
  //  function handleClick() {
  //  setDisplayFormerOfficers(!displayFormerOfficers);
  //}
  //*/}

  //{/*
  //  const squareButtonStyle = {
  //  display: "flex",
  //  justifyContent: "center",
  //  marginTop: "70px",
  //};
  //*/}
  return (
    <div>
      <div>
        <h1>Officer Team</h1>
        <OfficerCards officers={officers} />
      </div>

      {/* BUTTON FOR FORMER OFFICERS HOWEVER, AS OF 10/15/2024, WE DO NOT HAVE ANY FORMER OFFICERS
      //<div style={squareButtonStyle}>
      //  <SquareButton variant="contained" onClick={handleClick}>
      //    Former Officers
      //  </SquareButton>
      //</div>
      //

      //{displayFormerOfficers && (
      //  <div>
      //    <h1>Former Officers</h1>
      //    <OfficerCards officers={formerOfficers} />
      //  </div>
      //  
      //)}
      //*/}
    </div>
  );
}
